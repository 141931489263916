import React, { useContext, useState } from "react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import AppContext from "../../AppContext";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AvatarLink } from "../basic/AvatarLink";
import { Button } from "../basic/Button";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { Logo } from "../basic/Logo";
import EventIcon from "@mui/icons-material/Event";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHome";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EllipsisHorizontalIcon from "@heroicons/react/24/outline/EllipsisHorizontalIcon";
import NotificationsIconSubbed from "../complex/NotificationsIconSubbed";
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

export default function SiteDrawer(props) {
  const [globalState, setGlobalState, q, p, connectionStatus] =
    useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [postBoxOpen, setPostBoxOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const tryScrollToTop = (e) => {
    if (e.currentTarget.getAttribute("href") === location.pathname) {
      e.preventDefault();
      window.scrollTo({ top: 0 });
    }
  };
  const togglePostBox = () => {
    setPostBoxOpen(!postBoxOpen);
  };

  const DrawerList = (
    <Box sx={{ width: "75vw", zIndex: "2000" }} pb={2}>
      <Stack py={1} px={2} alignItems={"flex-end"}>
        <IconButton
          onClick={toggleDrawer(false)}
          variant="outlined"
          sx={{ width: 36, height: 36 }}
        >
          <SvgIcon sx={{ width: 16, height: 16 }}>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <Stack mb={2}>
        <Stack mb={2} px={2} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" color="textPrimary">
            <strong>Navigate</strong>
          </Typography>
        </Stack>

        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          sx={{ height: "100%" }}
          mx={2}
          mb={2}
        >
          <List sx={{ padding: 0 }}>
            {[
              { text: "Home", path: "/" },
              { text: "Contacts", path: "/contacts" },
              { text: "Messages", path: "/messages" },
              { text: "Notifications", path: "/notifications" },
              { text: "Search", path: "/search" },
            ].map((textObj, index) => (
              <ListItem
                key={textObj?.text}
                disablePadding
                sx={{
                  display: "block",
                  marginBottom: theme.spacing(1),
                }}
              >
                <ListItemButton
                  component={ReactLink}
                  onClick={tryScrollToTop}
                  to={`${textObj?.path}`}
                  sx={{
                    borderRadius: 8,
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "black",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index === 0 && <HomeOutlinedIcon />}
                    {index === 1 && <GroupOutlinedIcon />}
                    {index === 2 && <ChatBubbleOutlineOutlinedIcon />}
                    {index === 3 && <NotificationsIconSubbed />}
                    {index === 4 && <SearchIcon />}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={textObj?.text}
                      sx={{
                        opacity: 1,
                        color: "black",
                        "&>span": { fontSize: "1.2rem" },
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}

            {["Create"].map((text, index) => (
              <>
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Button
                    onClick={togglePostBox}
                    sx={{
                      borderRadius: 8,
                      minHeight: 48,
                      minWidth: 48,
                      justifyContent: open ? "center" : "center",
                      width: { xs: "100%" },
                      ml: { xs: 0 },
                      // px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        // color: "#2D9CDB",
                        color: "white",
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {postBoxOpen ? <CloseIcon /> : <AddIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{
                        textAlign: "left",
                        display: { xs: "block" },
                        opacity: open ? 1 : 0,
                        // color: "#2D9CDB",
                        "&>span": { fontSize: "1.2rem" },
                      }}
                    />
                  </Button>
                </ListItem>
                {postBoxOpen &&
                  ["Activity", "Post"].map((text, i) => (
                    <ListItem
                      key={`li-${text}`}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        component={ReactLink}
                        // onClick={
                        //   i === 0 ? onClickCreateActivity : onClickCreatePost
                        // }
                        to={i === 0 ? "/create-activity" : "/create-post"}
                        sx={{
                          borderRadius: 8,
                          minHeight: 48,
                          justifyContent: "center",
                          px: 2.5,
                          // px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: "black",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {i === 0 ? <EventIcon /> : <AddHomeOutlinedIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{
                            display: { xs: "block" },
                            opacity: open ? 1 : 0,
                            // color: "#2D9CDB",
                            "&>span": { fontSize: "1.2rem" },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </>
            ))}
          </List>
        </Stack>
      </Stack>

      <Stack py={1} mb={2}>
        <Stack mb={2} px={2} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" color="textPrimary">
            <strong>Get connected</strong>
          </Typography>
        </Stack>

        <List sx={{ padding: 0 }}>
          {[
            [
              "Soheil Nemri",
              "7f58d4ce-ba42-49f1-a595-02d252908b42",
              "https://cdn.qa.sociogram.com/app/features/user/profile/photos/31588345-5502-4dd1-af15-25251deecb99.jpeg",
            ],
            [
              "Dany Entezari",
              "a749bf95-77c0-4178-b7ac-5bb78f1e137d",
              "https://d2mgxt9bsinkzr.cloudfront.net/filters:quality(25)/fit-in/128x128/app/features/user/profile/photos/0f6c81dc-cfcb-493c-ae2f-b7a9a4792989.jpeg",
            ],
          ].map((itemObj, i) => (
            <ListItem
              key={`trending-item-${i}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                component={ReactLink}
                to={`/profile/${itemObj[1]}`}
                sx={{
                  minHeight: 48,
                  px: 2,
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                >
                  <AvatarLink src={itemObj[2]}>{itemObj[0]}</AvatarLink>
                  <Typography sx={{ flexGrow: 1 }}>{itemObj[0]}</Typography>
                  <Button onClick={toggleDrawer(false)} size="small">
                    View
                  </Button>
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Stack>
        <Box mb={2} mx={2}>
          <Typography
            gutterBottom
            variant="body1"
            fontWeight={700}
            color="textPrimary"
          >
            Sitemap
          </Typography>
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            {[
              {
                title: "Privacy Policy",
                url: "https://www.sociogram.com/privacy-policy",
              },
              {
                title: "Terms and Conditions",
                url: "https://www.sociogram.com/terms-conditions",
              },
              {
                title: "Community Guidelines",
                url: "https://www.sociogram.com/community-guidelines",
              },
              {
                title: "FAQ",
                url: "https://www.sociogram.com/faq",
              },
              {
                title: "Feedback Form",
                url: "https://www.sociogram.com/feedback",
              },
              {
                title: "Report a Bug",
                url: "https://www.sociogram.com/report-bug",
              },
              {
                title: "Support Ticket",
                url: "https://www.sociogram.com/support-ticket",
              },
              {
                title: "General Inquiries",
                url: "https://www.sociogram.com/general-enquiry",
              },
            ].map((obj, i) => {
              return (
                <li>
                  <ReactLink
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      textDecoration: "none",
                      width: "fit-content",
                      display: "inline-block",
                    }}
                    to={obj.url}
                    target="_blank"
                  >
                    <Typography variant="caption">{obj.title}</Typography>
                  </ReactLink>
                  <Typography
                    sx={{
                      mx: 0.8,
                      color: "rgba(0, 0, 0, 0.6)",
                      display: "inline-block",
                    }}
                    variant="body1"
                  ></Typography>
                </li>
              );
            })}
          </ul>
        </Box>

        <Stack px={2}>
          <Typography variant="caption" color="textSecondary">
            &copy; {new Date().getFullYear()} SOCIOGRAM
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <>
      {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Box
        sx={{ flexGrow: 1, textDecoration: "none", cursor: "pointer" }}
        component={"div"}
        onClick={toggleDrawer(true)}
      >
        {props.children}
      </Box>
    </>
  );
}
