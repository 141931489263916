import React, { useRef, useState, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { SearchTextField } from "../basic/SearchTextField";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import debounce from "lodash/debounce";
import { styled } from "@mui/system";
import styledcomponent from "styled-components";

const breakpoints = {
  mobile: "480px",
  tablet: "768px",
  desktop: "1024px",
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MapContainer = styledcomponent.div`
  width: 100%;
  @media (min-width: 0px) {
    height: calc(100vh - 266px);
  }
  @media (min-width: ${breakpoints.tablet}) {
    height: calc(100vh - 136px);
  }
`;

const center = {
  // name: "Downtown Dubai",
  lng: 55.2677267,
  lat: 25.1941284,
};

const LocationSearchContainer = styled(Box)({
  position: "relative",
  marginBottom: "20px",
  marginLeft: "16px",
  marginRight: "16px",
});

const SuggestionsDropdown = styled("ul")({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  background: "white",
  border: "1px solid #ddd",
  borderRadius: "4px",
  maxHeight: "200px",
  overflowY: "auto",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const SuggestionItem = styled("li")({
  padding: "8px 12px",
  cursor: "pointer",
  "&:hover": {
    background: "#f5f5f5",
  },
});

const SelectLocationMapComponent = (props) => {
  const p = props.p;
  const [selectedLocation, setSelectedLocation] = useState(center);
  const [searchInput, setSearchInput] = useState(p?.submission?.location?.q);
  const [suggestions, setSuggestions] = useState([]);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const map = useRef();
  var searchTextField = useRef();

  const handleMapClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const searchLocations = (query) => {
    if (map.current) {
      const service = new window.google.maps.places.PlacesService(map.current);
  
      const request = {
        query: query,
        fields: ['name', 'formatted_address', 'geometry'], // Customize fields as needed
      };
  
      service.textSearch(request, (results, status) => {
        if (status === window?.google?.maps?.places?.PlacesServiceStatus?.OK) {
          // const resultsWithLatLon = results.map((place) => ({
          //   ...place,
          //   name: place.name,
          //   address: place.formatted_address,
          //   lat: place.geometry.location.lat(),
          //   lon: place.geometry.location.lng(),
          // }));
  
          // setSuggestions(resultsWithLatLon);

          setSuggestions(results); // Store the results in the state
          // console.log('Search Results:', results);
        } else {
          console.error('PlacesService search failed:', status);
        }
      });
    } else {
      console.error('Map instance not available');
    }
  };
  

  const debouncedSearch = useCallback(
    debounce((query) => searchLocations(query), 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e?.target?.value || suggestions?.[0]?.name || searchInput;

    setSearchInput(value);
    if (value?.length > 2) {
      debouncedSearch(value);
    } else {
      setSuggestions([]);
    }
  };
  
  const handleInputClear = () => {  
    setSearchInput("");
    setSuggestions([]);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion.name);
    setSuggestions([]);
    const location = suggestion.geometry.location;

    const lat = location.lat();
    const lng = location.lng();

    setSelectedLocation({
      lat: lat,
      lng: lng,
    });

    // Pass the details to SelectLocationScreen
    props.selectLocationCallback({
      name: suggestion.name,
      lat: lat,
      lng: lng,
    });

    if (mapRef.current) {
      mapRef.current.panTo({ lat: lat, lng: lng });
    }
    if (markerRef.current) {
      markerRef.current.setPosition({ lat: lat, lng: lng });
    }
  };

  const handleMarkerDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { location: { lat: newLat, lng: newLng } },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const newAddress = results[0].formatted_address;
          setSearchInput(newAddress);
          setSelectedLocation({
            lat: newLat,
            lng: newLng,
          });

          // Pass the new location details to SelectLocationScreen
          props.selectLocationCallback({
            name: newAddress,
            lat: newLat,
            lng: newLng,
          });
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      }
    );
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const handleMarkerLoad = (marker) => {
    markerRef.current = marker;
  };

  return (
    <>
      <Box mb={2.5} mx={2}>
        <SearchTextField
          id="location"
          fullWidth={true}
          size="medium"
          placeholder="Search"
          sx={{ borderRadius: "100%" }}
          inputRef={(thisElem) => (searchTextField = thisElem)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleInputChange} aria-label="Search" >
                  <Search />
                </IconButton>
                <IconButton onClick={handleInputClear} aria-label="Search" edge="end">
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchInput}
          onChange={handleInputChange}
        />
        {suggestions.length > 0 && (
          <LocationSearchContainer>
            <SuggestionsDropdown className="suggestions-dropdown">
              {suggestions.map((suggestion) => (
                <SuggestionItem
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name}
                </SuggestionItem>
              ))}
            </SuggestionsDropdown>
          </LocationSearchContainer>
        )}
      </Box>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
        <MapContainer>
          <GoogleMap
            onLoad={(mapInstance) => {
              map.current = mapInstance;
            }}
            options={{
              styles: [
                {
                  featureType: "all",
                  elementType: "labels",
                  stylers: [{ visibility: "on" }],
                },
              ],
            }}
            mapContainerStyle={containerStyle}
            center={selectedLocation}
            zoom={15}
            onClick={handleMapClick}
          >
            <Marker
              position={selectedLocation}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              onLoad={handleMarkerLoad}
            />
          </GoogleMap>
        </MapContainer>
      </LoadScript>
    </>
  );
};

export default SelectLocationMapComponent;
