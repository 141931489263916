import React, {
  forwardRef,
  useLayoutEffect,
  useContext,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
// import Box from '@mui/material/Box';
import { Button } from "../basic/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import "./HomeScreen.css";
import timestampdifference from "../utils/timestampdifference";
import { generateClient, post } from "aws-amplify/api";
import listPostsByStatus from "./queries/listPostsByStatus.js";
import { Virtuoso } from "react-virtuoso";

const client = generateClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const ResponsiveBoxContainer = styled(Box)(({ theme }) => ({
  background: "solid 1px rgba(0,0,0,0.15)",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //background: "#f6f7f9",
  },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 0,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

const ResponsiveDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    //display: "none",
  },
}));

const HomeScreen = forwardRef(function HomeScreen(props, ref) {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    // feedData: p.feedData || [],
    nextToken: null,
    nextTokenFeed: p.nextTokenFeed || null,
    ...p,
    // virtuosoSnapshot: p.virtuosoSnapshot,
    // virtuosoKey: p.virtuosoKey || 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [page, setPage] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(p.homeFeedScrollOffset || 0);

  const navigate = useNavigate();
  const virtuosoRef = useRef([]);
  const listRef = useRef(null);
  const homeFeedContainer = useRef(null);

  const fetchData = async () => {
    // if(p.feedData) return;

    try {
      setIsLoading(true);
      const res = await client.graphql({
        query: listPostsByStatus(
          p.userData?.userId,
          p.nextTokenFeed ? `, nextToken: "${p.nextTokenFeed}"` : ""
        ),
        fetchPolicy: "network-only",
      });

      if (res.errors) {
        console.error("GraphQL errors:", res.errors);
        alert(
          "There was an issue retrieving the data. Please try again later."
        );
        return;
      }

      p.feedData = p.feedData
        ? [...p.feedData, ...res.data.listPostsByStatus.items]
        : [...res.data.listPostsByStatus.items];

      p.nextTokenFeed = res.data.listPostsByStatus.nextToken;

      setState({
        ...state,
        ...p,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/signin");
    } finally {
      p.homeScreenFetchLock = 0;
      setIsLoading(false);
    }
  };

  const fetchNewData = async () => {
    try {
      setIsLoadingNew(true);
      const res = await client.graphql({
        query: listPostsByStatus(
          p.userData?.userId,
          "",
          "",
          p.feedData?.[0].createdAt
        ),
        fetchPolicy: "network-only",
      });

      if (res.errors) {
        console.error("GraphQL errors:", res.errors);
        alert(
          "There was an issue retrieving the data. Please try again later."
        );
        return;
      }

      const newItems = res.data.listPostsByStatus.items || [];
      const existingIds = new Set(p.feedData?.map((item) => item.id) || []);
      const uniqueNewItems = newItems.filter(
        (item) => !existingIds.has(item.id)
      );
      p.feedData = uniqueNewItems.concat(p.feedData || []);

      p.nextTokenFeed = res.data.listPostsByStatus.nextToken;

      // listRef.current.scrollTo(0);

      setState({
        ...state,
        ...p,
      });
      setIsLoadingNew(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingNew(false);
    } finally {
      p.homeScreenFetchLock = 0;
      setIsLoadingNew(false);
    }
  };

  const showNewPosts = () => {
    fetchNewData();
    homeFeedContainer.current.scrollIntoView();
    setGlobalState({ ...globalState, showNewPostsButton: false });
  };

  useEffect(() => {
    (async () => {
      p.prevPage = p.currentPage || "home";
      p.currentPage = "home";
      p.journey = "init";
      p.journey = "home";

      p.lockHomeFeedScrollOffset = false;
      p.homeScreenFetchLock = 1;

      if (!p.feedData || p.feedData.length === 0) {
        fetchData();
      } else {
        p.virtuosoKey = p.virtuosoKey > 0 ? p.virtuosoKey + 1 : 1;
      }

      // console.log("-p.virtuosoSnapshot", p.virtuosoSnapshot);
      if (p.feedData?.length > 0 && p.virtuosoSnapshot) {
        setState({
          ...state,
          ...p,
          // feedData: p.feedData,
          // virtuosoSnapshot: p.virtuosoSnapshot,
          // virtuosoKey: p.virtuosoKey || 1,
        });
      }
    })();
  }, []);

  useLayoutEffect(
    () => () => {
      if (!virtuosoRef?.current) return;

      try {
        virtuosoRef?.current?.getState((snapshot) => {
          p.virtuosoSnapshot = snapshot;
          p.virtuosoKey = p.virtuosoKey > 0 ? p.virtuosoKey + 1 : 1;
          // console.log("p.virtuosoKey", p.virtuosoKey);
          // console.log("p.virtuosoSnapshot", p.virtuosoSnapshot)
        });
      } catch (e) {
        console.error("Error getting virtuoso snapshot", e);
      }
    },
    []
  );

  const Row = memo(({ index, style }) => {
    const itemObj = state.feedData?.[index];
    const {
      id,
      text,
      createdAt,
      images,
      link,
      userObject,
      activityObject,
      postObject,
      commentsCount,
      reactions,
      reactionsCount,
      postTagUsers,
    } = itemObj;

    const deletedStyle = { height: 0, display: "none" };
    const [deletedStyleState, setDeletedStyleState] = useState();

    const resizeOnDelete = () => {
      setDeletedStyleState(deletedStyle);

      p.feedData = p.feedData.filter((obj) => obj.id !== id);

      setState({
        ...state,
        feedData: p.feedData,
      });
      if (!p.deletedPosts) {
        p.deletedPosts = [id];
      } else {
        p.deletedPosts.push(id);
      }
    };
    if (!itemObj) return null;

    const startTimestamp = activityObject
      ? new Date(activityObject.activityStart)
      : null;
    const endTimestamp = activityObject
      ? new Date(activityObject.activityEnd)
      : null;
    var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
    var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

    if (startTimestamp) {
      start = {
        sDay: startTimestamp.getDate(),
        sMonth: startTimestamp.getMonth(),
        sHour: String(startTimestamp.getHours()).padStart(2, "0"),
        sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
      };
    }
    if (endTimestamp) {
      end = {
        eDay: endTimestamp.getDate(),
        eMonth: endTimestamp.getMonth(),
        eHour: String(endTimestamp.getHours()).padStart(2, "0"),
        eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
      };
    }

    const { sDay, sMonth, sHour, sMinute } = start;
    const { eDay, eMonth, eHour, eMinute } = end;

    return (
      <div id={`here-${index}`} style={style}>
        <ResponsiveStack
          id={`feedcard-${id}`}
          key={`feedcard-${id}`}
          pt={2}
          px={4}
          pb={0}
          mb={0}
        >
          <ActivityPostCard
            id={id}
            cropText={true}
            deleteCallback={resizeOnDelete}
            currentUserObject={state?.userData}
            userObject={userObject}
            userAvatar={
              userObject?.images?.items?.length > 0 &&
              userObject?.images?.items?.[0]?.url
            }
            userName={`${userObject?.givenName} ${userObject?.familyName}`}
            userId={userObject?.id}
            image={images?.items?.[0]?.url}
            imagesData={images?.items}
            text={text}
            commentsCount={commentsCount}
            reactionsCount={reactionsCount}
            likeReactionID={reactions?.items?.[0]?.id}
            liked={reactions?.items?.[0]?.type === "HEART" ? true : false}
            shareType={
              (activityObject && "activity") ||
              (postObject && "post") ||
              (link && "link") ||
              null
            }
            createdAt={createdAt}
            postTagUsers={postTagUsers}
            rating={
              Math.round(
                (userObject?.sumOfVotes / userObject?.numberOfVotes) * 100
              ) / 100
            }
            activityObject={
              activityObject && {
                ...activityObject,
                id: activityObject.id,
                category: activityObject.interest.parentInterest.title,
                userName: `${activityObject.userObject?.givenName} ${activityObject.userObject?.familyName}`,
                text: activityObject.description,
                currency: activityObject.currency || "",
                price: activityObject.price
                  ? parseFloat(activityObject.price).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "FREE",
                day: sDay,
                month: sMonth,
                privacy: activityObject.privacy,
                startTime: `${sHour}:${sMinute}`,
                endTime: `${eHour}:${eMinute}`,
                participants: activityObject.numberOfParticipants,
                rsvped: activityObject.activityAttendance.items.length,
                address: activityObject.address,
                userAvatar: activityObject?.userObject?.images?.items?.[0]?.url,
                userId: activityObject?.userObject?.id,
                attendees: activityObject.activityAttendance.items.slice(0, 5),
                remainingDays: timestampdifference(new Date(), endTimestamp),
              }
            }
            postObject={
              postObject && {
                id: postObject.id,
                imageSrc:
                  postObject.images?.items?.[0]?.url ||
                  postObject?.userObject?.images?.items?.[0]?.url,
                username: `${postObject?.userObject?.givenName} ${postObject?.userObject?.familyName}`,
                userId: postObject?.userObject?.id,
                text:
                  `"${postObject.text}"` ||
                  postObject?.activityObject?.description,
                type: postObject.activityObject && "Activity",
                name: postObject?.activityObject?.name,
              }
            }
            link={
              link && {
                imageSrc: link?.imageUrl,
                name: link?.title,
                text: " ",
                type: "link",
                url: link.url,
              }
            }
          />
        </ResponsiveStack>
        <ResponsiveDivider />
      </div>
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveBoxContainer
        ref={(thisElem) => (homeFeedContainer.current = thisElem)}
      >
        <div>
          {!p.feedData &&
            (!state.feedData || state.feedData?.length === 0) &&
            [1, 2, 3].map((v, i) => (
              <ResponsiveStack
                sx={{ "&:hover": { background: "none" } }}
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={0}
                py={2}
                px={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={170}
                />
              </ResponsiveStack>
            ))}

          <Stack
            sx={{
              position: { xs: "fixed", sm: "fixed" },
              zIndex: "1000",
              top: globalState?.showNewPostsButton ? 32 : -48,
              width: "100%",
              transition: "all 0.25s",
            }}
            alignItems={"center"}
            maxWidth={"sm"}
          >
            <Button
              onClick={showNewPosts}
              sx={{
                width: 120,
                boxShadow: "0px 1px 5px rgba(0,0,0,0.5)",
              }}
            >
              New Posts
            </Button>
          </Stack>

          {isLoadingNew && (
            <Stack sx={{ width: "100%" }} justifyContent={"center"} py={2}>
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Stack>
          )}

          <div ref={listRef} style={{ minHeight: 10000 }}>
            {state.feedData?.length > 0 && !state.virtuosoSnapshot?.ranges && (
              <Virtuoso
                key={(index) => `key-${index}`}
                id="the-virtuoso"
                ref={virtuosoRef}
                useWindowScroll={true}
                style={{ height: "100%" }}
                data={p.feedData || state.feedData || []}
                totalCount={p.feedData?.length || state.feedData?.length || 0}
                itemContent={(index) => <Row index={index} />}
                endReached={fetchData}
              />
            )}

            {state.feedData?.length > 0 &&
              state.virtuosoSnapshot?.ranges?.length > 0 && (
                <Virtuoso
                  id="the-virtuoso"
                  key={(index) => `key-${index}`}
                  ref={virtuosoRef}
                  data={state.feedData || []}
                  restoreStateFrom={state.virtuosoSnapshot}
                  // computeItemKey={(index) =>
                  //   `item-${state?.feedData?.[index]?.id?.toString()}`
                  // }
                  totalCount={state?.feedData?.length}
                  itemContent={(index) => <Row index={index} />}
                  style={{ height: "100%" }}
                  endReached={fetchData}
                  useWindowScroll={true}
                />
              )}
          </div>

          {isLoading && (
            <Stack sx={{ width: "100%" }} justifyContent={"center"} py={2}>
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Stack>
          )}
        </div>
      </ResponsiveBoxContainer>
    </ThemeProvider>
  );
});

const MemoizedHomeScreen = memo(HomeScreen);

export const HomeScreenLayout = forwardRef((props, ref) => {
  return (
    <ResponsiveLayoutSidebar
      pt={{ xs: 8, sm: 0 }}
      pb={{ xs: 8, sm: 0 }}
      bg={"none"}
    >
      <MemoizedHomeScreen ref={ref} {...props} />
    </ResponsiveLayoutSidebar>
  );
});
