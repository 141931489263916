import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../AppContext";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Logo } from "../basic/Logo";
import { AppDialog } from "../complex/AppDialog";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import wallpapers from "./wallpapers.json";
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ReponsiveGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ReponsivePositionBox = styled(Grid)(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  width: "100%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: `${theme.spacing(6)} 0px ${theme.spacing(0)} 0px`,
  // },
  [theme.breakpoints.down("md")]: {
    // minHeight: 'calc(100vh - 40px)',
    // height: 'calc(100% - 0px)',
    // padding: `${theme.spacing(6)} 0px ${theme.spacing(16)} 0px`
    // top: '35%'
    top: 0,
    transform: "translate(-50%, 0%)",
    padding: `${theme.spacing(6)} 0px ${theme.spacing(0)} 0px`,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const OnboardingScreenResponsiveLayout = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [toasterMessage, setToasterMessage] = useState({ message: "" });
  const [appDialog, setAppDialog] = useState({ open: false });

  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Check if the child is a valid React element
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        setToasterMessage,
        appDialog,
        setAppDialog,
      });
    }
    return child;
  });

  const getRandomWallpaper = () => {
    if (!p.selectedOnboardingWallpaper) {
      const randomImage =
        wallpapers[Math.floor(Math.random() * wallpapers.length)];
      p.selectedOnboardingWallpaper = randomImage;
      return randomImage;
    } else {
      return p.selectedOnboardingWallpaper;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid sx={{ minHeight: `100vh`, width: "100vw", margin: 0 }} container>
        <Grid
          sx={{
            height: "100vh",
            overflowY: "scroll",
          }}
          item
          xs={12}
          md={6}
        >
          <Box
            position={"relative"}
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: { xs: 400, sm: 450, md: 520 },
              margin: "0 auto",
              background: "white",
            }}
          >
            <ReponsivePositionBox sx={props.reponsivePositionBoxSx}>
              {useMediaQuery(theme.breakpoints.up("md")) && !props.hideLogo && (
                <Box mb={4} px={4}>
                  <Logo color="color" size={48} />
                </Box>
              )}

              {childrenWithProps}
            </ReponsivePositionBox>
          </Box>
        </Grid>
        <ReponsiveGrid item xs={12} md={6}>
          <Box
            sx={{
              background: `url("https://d2mgxt9bsinkzr.cloudfront.net/filters:quality(50)/fit-in/1080x1080/app/wallpapers/${getRandomWallpaper()}") center center`,
              backgroundSize: "cover",
              height: "100%",
            }}
          />
          <Box
            sx={{
              position: "fixed",
              display: "none",
              bottom: theme.spacing(19),
              right: theme.spacing(6),
            }}
          >
            <Stack direction="column">
              <Typography
                color="white"
                variant="caption"
                sx={{ lineHeight: "1.2em", textAlign: "right" }}
              >
                <strong></strong>
              </Typography>
              <Typography
                color="white"
                variant="caption"
                sx={{ lineHeight: "1.2em", textAlign: "right" }}
              >
                
              </Typography>
              <Typography
                color="white"
                variant="caption"
                sx={{ lineHeight: "1.2em", textAlign: "right" }}
              >
                
              </Typography>
            </Stack>

            <ResponsiveToaster
              sx={{ position: "sticky" }}
              severity={globalState?.toaster?.type}
              open={globalState?.toaster?.open}
              toasterMessage={globalState?.toaster?.message}
              setToasterMessage={() =>
                setGlobalState({
                  ...globalState,
                  toaster: {
                    ...globalState?.toaster,
                    open: false,
                  },
                })
              }
            />
            <AppDialog
              open={appDialog.open}
              buttonDisabled={appDialog.buttonDisabled}
              maxButtonWidth={275}
              confirmText={appDialog.confirmText}
              onConfirm={() => {
                appDialog.onConfirm();
                setAppDialog({ ...appDialog, open: false });
              }}
              onClose={() => setAppDialog({ ...appDialog, open: false })}
            >
              {appDialog.dialogChildren}
            </AppDialog>
          </Box>
        </ReponsiveGrid>
      </Grid>
    </ThemeProvider>
  );
};

OnboardingScreenResponsiveLayout.propTypes = {};

OnboardingScreenResponsiveLayout.defaultProps = {
  color: "primary",
};
